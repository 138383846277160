<template>
  <fragment>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title>{{ organization.name }}</v-card-title>
          <v-card-text>
            <div v-html="$formatParagraph([[organization.address], [organization.additional], [organization.zip, organization.city]])" />
            <div class="mt-4" v-if="organization.website || organization.phone" v-html="$formatParagraph([[$options.filters.formatUrl(organization.website)], [organization.phone]])" />
          </v-card-text>
          <v-card-actions v-if="$can('update', 'Organization')">
            <v-spacer></v-spacer>
            <v-btn text @click="updateOrganization"><v-icon left>mdi-pencil</v-icon>bearbeiten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <Update />
  </fragment>
</template>

<script>
import url from 'url';
import urlFormat from 'format-url';

export default {
  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  components: {
    Update: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/organization/Update.vue'),
  },

  filters: {
    formatUrl(value) {
      if (!value) return '';

      const urlFormated = urlFormat(value);
      const urlParts = url.parse(urlFormated);
      return `<a href="${urlParts.href}" target="_blank">${urlParts.host}</a>`;
    },
  },

  methods: {
    updateOrganization() {
      this.$store.commit('organization/sync');
      this.$store.commit('organization/dialog/update', true);
    },
  },
};
</script>
