<template>
  <v-dialog v-model="show" max-width="600" @input="handleDialog">
    <v-card>
      <base-alert v-if="error">{{ error.message }}</base-alert>
      <v-card-title class="headline">Organisation löschen?</v-card-title>
      <v-card-text>
        <p>
          Möchten Sie die Organisation
          <strong>«{{ dialog.name }}»</strong> wirklich löschen? Dabei gehen alle Daten zu dieser Organisation verloren.
        </p>
        <p>Der Vorgang kann nicht rückgängig gemacht werden.</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
        <v-btn color="error" @click.stop="onDelete" :loading="loading" :disabled="loading">Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      dialog: 'organization/dialog/delete',
      loading: 'loading/get',
      error: 'error/get',
    }),
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    onClose() {
      this.$store.commit('organization/dialog/delete', false);
      this.$store.commit('error/set', null);
    },
    onDelete() {
      this.$store.dispatch('organization/delete', { id: this.dialog.id, path: this.dialog.path });
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
      }
    },
  },
};
</script>
