<template>
  <v-dialog v-model="show" max-width="900" @input="handleDialog">
    <Form title="Organisation bearbeiten" @submit="onSubmit" @close="onClose" ref="form">
      <v-btn type="submit" color="primary" :disabled="loading" :loading="loading">Speichern</v-btn>
    </Form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    Form: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/organization/Form.vue'),
  },

  computed: {
    ...mapGetters({
      loading: 'loading/get',
      dialog: 'organization/dialog/update',
    }),
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    dialog: {
      handler(value, oldValue) {
        if (value === false && oldValue === true) {
          this.$refs.form.onClose();
        }
      },
    },
  },

  methods: {
    onSubmit(values) {
      this.$store.dispatch('organization/update', values);
    },
    onClose() {
      this.$store.commit('organization/dialog/update', false);
    },
    handleDialog(value) {
      if (value === false) {
        this.$refs.form.onClose();
      }
    },
  },
};
</script>
