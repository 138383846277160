var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c('v-card-text',[(_vm.error)?_c('base-alert',[_vm._v(_vm._s(_vm.error.message))]):_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"info","dense":"","color":"primary"}},[_vm._v(" wow-wow zeigt deine Adresse nur dann, wenn du diese später als Veranstaltungsort auswählst. In deinem Konto kannst du weitere Adressen hinzufügen. Angebote ohne Adresse erreichen den Benutzer nur bedingt. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":""}},[_c('ValidationProvider',{attrs:{"name":"Firmen-/Vereinsname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Firmen-/Vereinsname","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Strasse und Hausnummer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Strasse und Hausnummer","error-messages":errors},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Adresse (Zusatz)"},model:{value:(_vm.additional),callback:function ($$v) {_vm.additional=$$v},expression:"additional"}}),_c('v-row',{staticClass:"fix-row-space"},[_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"PLZ","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"PLZ","error-messages":errors},model:{value:(_vm.zip),callback:function ($$v) {_vm.zip=$$v},expression:"zip"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('ValidationProvider',{attrs:{"name":"Ort","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Ort","error-messages":errors},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"","offset-md":"1"}},[_c('v-text-field',{attrs:{"label":"Webseite","type":"url"},model:{value:(_vm.website),callback:function ($$v) {_vm.website=$$v},expression:"website"}}),_c('ValidationProvider',{attrs:{"name":"Telefon","rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Telefon","error-messages":errors},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.onClose($event)}}},[_vm._v("Abbrechen")]),_vm._t("default")],2)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }