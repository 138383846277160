<template>
  <fragment>
    <v-row>
      <v-col cols="12" sm="6" lg="4" xl="3" v-for="item in data" :key="`list-organization-${item.id}`">
        <v-card class="d-flex flex-column justify-space-between" height="100%">
          <v-card-title>{{ item.name }}</v-card-title>
          <v-card-text>
            <div v-html="$formatParagraph([[item.address], [item.additional], [item.zip, item.city]])" />
            <div class="mt-4" v-if="item.website || item.phone" v-html="$formatParagraph([[$options.filters.parseUrl(item.website)], [item.phone]])" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <base-mobile-button icon="mdi-pencil" tooltip="Organisation bearbeiten" :to="$url(`/organization/${item.id}`)">
              <span>Bearbeiten</span>
            </base-mobile-button>
            <base-mobile-button icon="mdi-delete" tooltip="Organisation löschen" @click="deleteOrganization(item)">
              <span>Löschen</span>
            </base-mobile-button>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="4" xl="3">
        <base-card-add @click="createOrganization" />
      </v-col>
    </v-row>
    <Create />
    <Delete />
  </fragment>
</template>

<script>
import url from 'url';
import urlFormat from 'format-url';

export default {
  components: {
    Create: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/organization/Create.vue'),
    Delete: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/organization/Delete.vue'),
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  filters: {
    parseUrl(value) {
      if (value) {
        const urlFormated = urlFormat(value);
        const urlParts = url.parse(urlFormated);
        return urlParts.host;
      }
      return null;
    },
  },

  methods: {
    createOrganization() {
      this.$store.commit('organization/dialog/create', true);
    },
    deleteOrganization(organization) {
      this.$store.commit('organization/dialog/delete', {
        id: organization.id,
        path: organization.path,
        name: organization.name,
      });
    },
  },
};
</script>
