<template>
  <Organizations :data="organizations" :loading="loading" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    Organizations: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/organization/Table.vue'),
  },

  computed: {
    ...mapGetters({
      loading: 'organization/loading',
      organizations: 'organization/all',
    }),
  },

  methods: {
    loadData() {
      this.$store.dispatch('organization/loadAllMain');
    },
    destroyData() {
      this.$store.commit('organization/resetAll');
    },
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
