import _ from 'lodash';

const data = [
  {
    text: 'Vor Ort',
    value: 'LOCATION',
  },
  {
    text: 'Online',
    value: 'ONLINE',
  },
  {
    text: 'Online & vor Ort',
    value: 'ONLINE_LOCATION',
  },
];

export default data;

export const getTextByValue = (value) => {
  const item = _.find(data, ['value', value]);
  return item.text;
};
