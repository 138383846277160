<template>
  <div class="mt-3">
    <base-title v-model="search">Organisationen</base-title>
    <v-card>
      <v-tooltip bottom open-delay="500" v-if="$can('create', 'Organization')">
        <template v-slot:activator="{ on }">
          <v-btn color="#64A175" dark small absolute top right fab @click="createOrganization" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Eine neue Organisation hinzufügen</span>
      </v-tooltip>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [10, 20, -1] }"
        :items-per-page.sync="itemsPerPage"
        virtual-rows
        sort-by="name"
      >
        <template v-slot:no-data>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Organisationen vorhanden</v-card-title>
            <v-card-text>Erstelle deine erste Organisation</v-card-text>
            <v-card-actions class="d-inline-flex" v-if="$can('create', 'Organization')">
              <v-btn color="primary" @click="createOrganization">Hinzufügen</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:no-results>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Organisationen gefunden</v-card-title>
            <v-card-text>Mit dem Begriff "{{ search }}" wurden keine Organisationen gefunden.</v-card-text>
            <v-card-actions class="d-inline-flex" v-if="$can('create', 'Organization')">
              <v-btn color="primary" @click="createOrganization">Hinzufügen</v-btn>
            </v-card-actions>
          </v-card>
        </template>
        <template v-slot:item.website="{ item }">{{ item.website | parseUrl }}</template>
        <template v-slot:item.email="{ item }">{{ item.email }}</template>
        <template v-slot:item.createdAt="{ item }">{{ item.createdAt | formatDate }}</template>
        <template v-slot:item.action="{ item }">
          <div :class="$vuetify.breakpoint.xs ? 'flex flex-wrap pb-2' : 'text-no-wrap'">
            <base-mobile-button icon="mdi-pencil" tooltip="Organisation bearbeiten" v-if="$can('update', 'Organization')" :to="`/organization/${item.id}`">
              <span>Bearbeiten</span>
            </base-mobile-button>
            <base-mobile-button icon="mdi-delete" tooltip="Organisation löschen" v-if="$can('delete', 'Organization')" @click.stop="deleteOrganization(item)">
              <span>Löschen</span>
            </base-mobile-button>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <Create />
    <Delete />
  </div>
</template>

<script>
import url from 'url';
import urlFormat from 'format-url';
import dayjs from 'dayjs';

export default {
  components: {
    Create: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/organization/Create.vue'),
    Delete: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/organization/Delete.vue'),
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    search: '',
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Adresse', value: 'address' },
      { text: 'PLZ', value: 'zip' },
      { text: 'Ort', value: 'city' },
      { text: 'Webseite', value: 'website' },
      { text: 'Erstellt am', value: 'createdAt' },
      { value: 'action', align: 'right', sortable: false },
    ],
  }),

  computed: {
    itemsPerPage: {
      get() {
        return Number.parseInt(localStorage.getItem('table-organization-items'), 10) || 20;
      },
      set(value) {
        localStorage.setItem('table-organization-items', value);
      },
    },
  },

  filters: {
    parseUrl(value) {
      if (value) {
        const urlFormated = urlFormat(value);
        const urlParts = url.parse(urlFormated);
        return urlParts.host;
      }
      return null;
    },
    formatDate(value) {
      if (value) {
        return dayjs(value.toDate()).format('DD.MM.YYYY HH:mm:ss');
      }
      return null;
    },
  },

  methods: {
    createOrganization() {
      this.$store.commit('organization/dialog/create', true);
    },
    deleteOrganization(organization) {
      this.$store.commit('organization/dialog/delete', {
        id: organization.id,
        path: organization.path,
        name: organization.name,
      });
    },
  },
};
</script>
