<template>
  <fragment>
    <base-title v-model="search">Alle Angebote</base-title>
    <v-row no-gutters justify="space-between">
      <v-col cols="12" sm="5" lg="4">
        <v-autocomplete label="Nach Benutzer filtern" v-model="user" :loading="loading" :items="users" item-text="displayName" item-value="email" clearable>
          <template v-slot:selection="data">{{ data.item.displayName || data.item.email }}</template>
          <template v-slot:item="data">
            <v-list-item-avatar color="primary">
              <span class="white--text">{{ data.item.shortName }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.displayName || data.item.email" />
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :loading="loading"
        :footer-props="{ itemsPerPageOptions: [10, 20, -1] }"
        :items-per-page.sync="itemsPerPage"
        item-key="id"
        virtual-rows
        sort-by="title.DE"
      >
        <template v-slot:no-data>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Veranstaltungen vorhanden</v-card-title>
          </v-card>
        </template>
        <template v-slot:no-results>
          <v-card flat class="my-5">
            <v-card-title class="headline d-inline-flex">Keine Veranstaltungen gefunden</v-card-title>
            <v-card-text>Mit dem Begriff "{{ search }}" wurden keine Veranstaltungen gefunden.</v-card-text>
          </v-card>
        </template>
        <template v-slot:top>
          <v-dialog v-model="dialog" max-width="600" @input="handleDialog" :persistent="loadingStore">
            <v-card>
              <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                <v-form @submit.prevent="handleSubmit(onSubmit)">
                  <v-toolbar dark>
                    <v-toolbar-title>Organisation ändern</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <base-alert v-if="error">{{ error.message }}</base-alert>
                    <ValidationProvider name="Organisation" rules="required" v-slot="{ errors }">
                      <v-autocomplete
                        label="Organisation"
                        v-model="organization"
                        :loading="loadingOrganization"
                        :items="organizations"
                        cache-items
                        :error-messages="errors"
                        item-text="name"
                        item-value="path"
                      >
                        <template v-slot:selection="data">
                          {{ data.item.name }}
                          <span class="caption grey--text ml-1" v-if="data.item.address">&nbsp;{{ data.item.address }}, {{ data.item.zip }} {{ data.item.city }}</span>
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.address">{{ data.item.address }}, {{ data.item.zip }} {{ data.item.city }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click.stop="onClose" :disabled="loadingStore">Abbrechen</v-btn>
                    <v-btn type="submit" color="primary" :loading="loadingStore" :disabled="loadingStore">Speichern</v-btn>
                  </v-card-actions>
                </v-form>
              </ValidationObserver>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.category="{ item }">
          <template v-if="item.category && item.category.name">{{ item.category.name.de }}</template>
        </template>
        <template v-slot:item.activityType="{ item }">{{ getActivityTypesText(item.activityType) }} ({{ getLocationTypesText(item.locationType) }})</template>
        <template v-slot:item.createdBy.email="{ item }">
          <v-tooltip bottom open-delay="500" v-if="item.createdBy">
            <template v-slot:activator="{ on }">
              <v-avatar color="primary" size="36" v-on="on">
                <span class="white--text">{{ item.createdBy.shortName }}</span>
              </v-avatar>
            </template>
            <span>{{ item.createdBy.displayName }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.updatedBy.email="{ item }">
          <v-tooltip bottom open-delay="500" v-if="item.updatedBy">
            <template v-slot:activator="{ on }">
              <v-avatar color="primary" size="36" v-on="on">
                <span class="white--text">{{ item.updatedBy.shortName }}</span>
              </v-avatar>
            </template>
            <span>{{ item.updatedBy.displayName }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.createdAt | formatDate }}</span>
            </template>
            <span>{{ item.createdAt | formatDateTime }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.dates.last="{ item }">
          <v-tooltip bottom open-delay="500" v-if="item.dates.last">
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.dates.last | formatDate }}</span>
            </template>
            <span>{{ item.dates.last | formatDateTime }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.images="{ item }">
          <v-tooltip bottom open-delay="500" v-if="$vuetify.breakpoint.smAndUp && item.images">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-file-image</v-icon>
            </template>
            <span>Hat ein oder mehrere Bilder</span>
          </v-tooltip>
          <div v-if="$vuetify.breakpoint.xs && item.images" class="d-flex align-center">
            Hat ein oder mehrere Bilder
            <v-icon right>mdi-file-image</v-icon>
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <div :class="$vuetify.breakpoint.xs ? 'flex flex-wrap pb-2' : 'text-no-wrap'">
            <base-mobile-button icon="mdi-factory" tooltip="Organisation bearbeiten" v-if="$can('update', 'Activity')" @click="updateActivity(item)">
              <span>Organisation bearbeiten</span>
            </base-mobile-button>
            <base-mobile-button icon="mdi-pencil" tooltip="Angebot bearbeiten" v-if="$can('update', 'Activity')" :to="activityUrl(item)">
              <span>Angebot bearbeiten</span>
            </base-mobile-button>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import dayjs from 'dayjs';
import { getTextByValue as getActivityTextByValue } from '@/helpers/activityTypes';
import { getTextByValue as getLocationTextByValue } from '@/helpers/locationTypes';

export default {
  components: { ValidationObserver, ValidationProvider },

  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    search: '',
    user: '',
    dialog: false,
    activityId: null,
    activityPath: null,
    organization: null,
  }),

  computed: {
    ...mapGetters({
      error: 'error/get',
      loadingStore: 'loading/get',
      loadingOrganization: 'organization/loading',
      organizations: 'organization/all',
    }),
    itemsPerPage: {
      get() {
        return Number.parseInt(localStorage.getItem('table-activities-items'), 10) || 20;
      },
      set(value) {
        localStorage.setItem('table-activities-items', value);
      },
    },
    headers() {
      return [
        { text: 'Veranstaltungstitel', value: 'title.DE' },
        { text: 'Organisation', value: 'organisation.name' },
        { text: 'Kategorie', value: 'category' },
        { text: 'Veranstaltungstyp', value: 'activityType' },
        {
          text: 'Erstellt von',
          value: 'createdBy.email',
          filter: (value) => {
            if (!this.user) {
              return true;
            }
            if (!value) {
              return false;
            }

            return value === this.user;
          },
        },
        { text: 'Geändert von', value: 'updatedBy.email' },
        { text: 'Erstellt am', value: 'createdAt' },
        { text: 'Letzte Veranstaltung', value: 'dates.last' },
        { value: 'images', sortable: false },
        { value: 'action', align: 'right', sortable: false },
      ];
    },
    users() {
      if (this.data.length <= 0) {
        return [];
      }

      const usersObj = {};
      // eslint-disable-next-line array-callback-return
      this.data.map((user) => {
        if (user.createdBy) {
          if (!usersObj[user.createdBy.id]) {
            usersObj[user.createdBy.id] = {
              path: user.createdBy.path,
              displayName: user.createdBy.displayName,
              shortName: user.createdBy.shortName,
              email: user.createdBy.email,
            };
          }
        }
      });

      const users = Object.keys(usersObj).map((k) => usersObj[k]);
      users.sort((a, b) => ((a.displayName > b.displayName) ? 1 : -1));

      return users;
    },
  },

  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value.toDate()).format('DD.MM.YYYY');
      }
      return null;
    },
    formatDateTime(value) {
      if (value) {
        return dayjs(value.toDate()).format('DD.MM.YYYY HH:mm');
      }
      return null;
    },
  },

  methods: {
    getActivityTypesText(value) {
      return getActivityTextByValue(value);
    },
    getLocationTypesText(value) {
      return getLocationTextByValue(value);
    },
    activityUrl(item) {
      if (item.organisation.organisation !== null) {
        return `/organization/${item.organisation.organisation.id}/organization/${item.organisation.id}/activity/${item.id}/edit`;
      }
      return `/organization/${item.organisation.id}/activity/${item.id}/edit`;
    },

    updateActivity(item) {
      this.activityId = item.id;
      this.activityPath = item.path;
      this.organization = item.organisation.path;
      this.$store.dispatch('organization/loadAll');
      this.dialog = true;
    },
    onSubmit() {
      this.$store.dispatch('activity/updateOrganization', { id: this.activityId, path: this.activityPath, organization: this.organization }).then(() => {
        if (!this.error) {
          this.onClose();
        }
      });
    },
    onClose() {
      this.dialog = false;
      this.activityId = null;
      this.activityPath = null;
      this.organization = null;
      this.$store.commit('organization/resetAll');
      this.$store.commit('error/set', null);
    },
    handleDialog(value) {
      if (value === false) {
        this.onClose();
      }
    },
  },
};
</script>
