var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('base-title',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_vm._v("Organisationen")]),_c('v-card',[(_vm.$can('create', 'Organization'))?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#64A175","dark":"","small":"","absolute":"","top":"","right":"","fab":""},on:{"click":_vm.createOrganization}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,2066293272)},[_c('span',[_vm._v("Eine neue Organisation hinzufügen")])]):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [10, 20, -1] },"items-per-page":_vm.itemsPerPage,"virtual-rows":"","sort-by":"name"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-card',{staticClass:"my-5",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline d-inline-flex"},[_vm._v("Keine Organisationen vorhanden")]),_c('v-card-text',[_vm._v("Erstelle deine erste Organisation")]),(_vm.$can('create', 'Organization'))?_c('v-card-actions',{staticClass:"d-inline-flex"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createOrganization}},[_vm._v("Hinzufügen")])],1):_vm._e()],1)]},proxy:true},{key:"no-results",fn:function(){return [_c('v-card',{staticClass:"my-5",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline d-inline-flex"},[_vm._v("Keine Organisationen gefunden")]),_c('v-card-text',[_vm._v("Mit dem Begriff \""+_vm._s(_vm.search)+"\" wurden keine Organisationen gefunden.")]),(_vm.$can('create', 'Organization'))?_c('v-card-actions',{staticClass:"d-inline-flex"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createOrganization}},[_vm._v("Hinzufügen")])],1):_vm._e()],1)]},proxy:true},{key:"item.website",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("parseUrl")(item.website)))]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.email))]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$vuetify.breakpoint.xs ? 'flex flex-wrap pb-2' : 'text-no-wrap'},[(_vm.$can('update', 'Organization'))?_c('base-mobile-button',{attrs:{"icon":"mdi-pencil","tooltip":"Organisation bearbeiten","to":("/organization/" + (item.id))}},[_c('span',[_vm._v("Bearbeiten")])]):_vm._e(),(_vm.$can('delete', 'Organization'))?_c('base-mobile-button',{attrs:{"icon":"mdi-delete","tooltip":"Organisation löschen"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteOrganization(item)}}},[_c('span',[_vm._v("Löschen")])]):_vm._e()],1)]}}])})],1),_c('Create'),_c('Delete')],1)}
var staticRenderFns = []

export { render, staticRenderFns }