<template>
  <div>
    <Activities v-if="auth && auth.organization" />
    <Organizations v-else-if="auth" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    Activities: () => import(/* webpackChunkName: "organization-detail" */ '@/components/scoped/views/Activities.vue'),
    Organizations: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/views/Organizations.vue'),
  },

  computed: {
    ...mapGetters({
      auth: 'auth/get',
    }),
  },

  // methods: {
  //   destroyData() {
  //     this.$store.commit('organization/unset');
  //   },
  // },

  // destroyed() {
  //   this.destroyData();
  // },
};
</script>
