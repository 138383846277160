<template>
  <v-card>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <v-toolbar dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <base-alert v-if="error">{{ error.message }}</base-alert>
          <v-alert border="left" colored-border type="info" dense color="primary" v-else>
            wow-wow zeigt deine Adresse nur dann, wenn du diese später als Veranstaltungsort auswählst. In deinem Konto kannst du weitere Adressen hinzufügen. Angebote ohne Adresse erreichen den
            Benutzer nur bedingt.
          </v-alert>
          <v-row>
            <v-col cols="12" md>
              <ValidationProvider name="Firmen-/Vereinsname" rules="required" v-slot="{ errors }">
                <v-text-field label="Firmen-/Vereinsname" v-model="name" :error-messages="errors" />
              </ValidationProvider>
              <ValidationProvider name="Strasse und Hausnummer" rules="required" v-slot="{ errors }">
                <v-text-field label="Strasse und Hausnummer" v-model="address" :error-messages="errors" />
              </ValidationProvider>
              <v-text-field label="Adresse (Zusatz)" v-model="additional" />
              <v-row class="fix-row-space">
                <v-col cols="4">
                  <ValidationProvider name="PLZ" rules="required|numeric" v-slot="{ errors }">
                    <v-text-field label="PLZ" v-model="zip" :error-messages="errors" />
                  </ValidationProvider>
                </v-col>
                <v-col cols="8">
                  <ValidationProvider name="Ort" rules="required" v-slot="{ errors }">
                    <v-text-field label="Ort" v-model="city" :error-messages="errors" />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md offset-md="1">
              <v-text-field label="Webseite" type="url" v-model="website" />
              <ValidationProvider name="Telefon" rules="phone" v-slot="{ errors }">
                <v-text-field label="Telefon" v-model="phone" :error-messages="errors" />
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="onClose" :disabled="loading">Abbrechen</v-btn>
          <slot></slot>
        </v-card-actions>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

const { mapFields } = createHelpers({
  getterType: 'organization/field',
  mutationType: 'organization/updateField',
});

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  components: { ValidationObserver, ValidationProvider },

  computed: {
    ...mapGetters({
      loading: 'loading/get',
      error: 'error/get',
    }),
    ...mapFields(['name', 'address', 'additional', 'zip', 'city', 'website', 'phone']),
  },

  methods: {
    onSubmit() {
      const data = {
        name: this.name,
        address: this.address,
        additional: this.additional,
        zip: this.zip,
        city: this.city,
        website: this.website,
        phone: this.phone,
      };

      this.$emit('submit', data);
    },
    onClose() {
      this.$store.commit('organization/reset');
      this.$store.commit('error/set', null);
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.fix-row-space {
  margin-top: -12px;
}
</style>
